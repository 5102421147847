let IMAGE_OVERLAY = {};



IMAGE_OVERLAY.showImage = link => {
  let linkId = link.dataset.id;
  let figure = document.querySelector(`figure[data-id="${linkId}"]`);
  let image = figure.querySelector('img');
  let borders = 24;

  BODY.dataset.activeFigure = linkId;

  figure.style.display = 'block';

  // Define figure's height is the image is defined
  if(image != null) {
    figure.style.height = `${image.offsetHeight + borders}px`;
  }
};



IMAGE_OVERLAY.imageFollowMouse = (link, x, y) => {
  let figure = document.querySelector(`figure[data-id="${link.dataset.id}"]`);

  let gap = 10;

  y = y - figure.offsetHeight;

  figure.style.left = `${x + gap}px`;
  figure.style.top = `${y - gap}px`;
};



IMAGE_OVERLAY.hideImage = () => {
  let activeFigureId = BODY.dataset.activeFigure;
  let activeFigure = document.querySelector(`figure[data-id="${activeFigureId}"]`);

  if(activeFigure) {
    activeFigure.style.display = 'none';
    activeFigure.style.top = 0;
    activeFigure.style.left = 0;

    BODY.dataset.activeFigure = '';
  }
};



IMAGE_OVERLAY.eventsDeclarations = (index, link) => {
  link.dataset.id = index;

  link.addEventListener('mouseover', () => {
    IMAGE_OVERLAY.showImage(link);
    link.addEventListener('mousemove', function(e) {
      IMAGE_OVERLAY.imageFollowMouse(link, e.pageX, e.pageY);
    });
  });
  link.addEventListener('mouseout', () => IMAGE_OVERLAY.hideImage());
};



IMAGE_OVERLAY.createElements = links => {
  // Create container for images, append it to body & add its class
  const CONTAINER = document.createElement('div');
  document.body.appendChild(CONTAINER);
  CONTAINER.classList.add('js-imageOverlayContainer');

  _.each(links, (link, index) => {
    let imageUrl = link.dataset.img;
    let imageContent = link.dataset.content;

    // Conditional toggles
    let imageExists = false;
    let contentExists = false;

    // Create figure block, add an ID to it, add it to the container
    let figure = document.createElement('figure');
    figure.dataset.id = index;
    CONTAINER.appendChild(figure);

    // If 'data-img' is defined
    if(imageUrl != '' && imageUrl != null) {
      imageExists = true;
      // Create image element, add its SRC url, add to the figure
      let img = document.createElement('img');
      img.src = imageUrl;
      figure.appendChild(img);
    }

    // If 'data-content' is defined
    if(imageContent != '' && imageContent != null) {
      contentExists = true;
      // Create caption, add its content, add it to the figure
      let caption = document.createElement('figcaption');

      // If there's no image, add class.
      if(!imageExists){
        caption.classList.add('no-img');
      }

      caption.innerHTML = imageContent;
      figure.appendChild(caption);
    }

    // If 'data-content' or 'data-img' is defined
    if(imageExists || contentExists) {
      IMAGE_OVERLAY.eventsDeclarations(index, link);
    }
  });
};



IMAGE_OVERLAY.init = () => {
  const BODY = document.querySelector('body');
  const links = document.querySelectorAll('.js-imageOverlay');

  IMAGE_OVERLAY.createElements(links);
};
