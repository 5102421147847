
// Globals
  let COMPLETED = {};
  let BODY = document.querySelector('body');
  const PAGE = BODY.dataset.page;



  // If 'landing' page
  if(PAGE === 'landing') {
    setTimeout(() => {
      runSequentially(COMPLETED.bands_funtions);
    }, 500);
  }




  // Activated in `2b-konami.js`
  COMPLETED.init = () => {
    // data-page="content"
    setTimeout(() => {
      BODY.dataset.page = 'akufen';

      COMPLETED.loadContent();
    }, 500);
  };




  COMPLETED.loadContent = () => {
    const request = new XMLHttpRequest();
    request.open('GET', 'akufen.html', true);

    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        let resp = request.responseText;

        let mainContainer = document.querySelector('.main-container');

        mainContainer.classList.remove('container-fluid');
        mainContainer.innerHTML = resp;

        runSequentially(COMPLETED.bands_funtions);
      }
    };
    request.send();
  };





  COMPLETED.bands_init = () => new Promise((resolve) => {
    let HTML_STYLES = window.getComputedStyle(document.querySelector(':root'));
    let col_amount = parseInt(HTML_STYLES.getPropertyValue('--grid-col-amount')) + 2; // Side gaps
    const CONTAINERS = document.querySelectorAll('.background-band');

    const BAND_TPL = '<span class="band"></span>';

    let bands_container = '';
    for(let x = 1; x <= col_amount; x++) {
      bands_container += BAND_TPL;
    }

    _.each(CONTAINERS, container => {
      container.innerHTML = bands_container;
    });

    resolve();
  });



  COMPLETED.bands_anim = () => new Promise((resolve) => {
    let HTML_STYLES = window.getComputedStyle(document.querySelector(':root'));

    const CONTAINERS = document.querySelectorAll('.background-band');

    _.each(CONTAINERS, function(container, index, list){
      let timeline = anime.timeline({
        targets: container.querySelectorAll('.band'),
        easing: [.39,.01,.2,.98]
      });

      let colorLine = HTML_STYLES.getPropertyValue(`--color-bands-section${index + 1}`).replace(/\s/g,'');
      colorLine = (colorLine == undefined) ? 'rgba(0,0,0,0.1)' : colorLine;


      timeline.add({
        translateY: '100%',
        delay: (el, i) => (i * 100),
        duration: (el, i) => (1000 + (i * 50))
      }).add({
        width: (el, i, l) => (i + 1) == l ? 0 : 1,
        delay: (el, i) => i * 70,
        duration: (el, i) => 600 + (i * 50)
      }).add({
        backgroundColor: colorLine,
        delay: (el, i) => (i * 100),
        duration: (el, i) => (500 + (i * 100)),
        offset: '-=800'
      });

      setTimeout(() => {
        resolve();
      }, 3400);
    });

  });



  COMPLETED.display_content = () => new Promise((resolve) => {
    const section1_items = document.querySelectorAll('[data-section="1"] > [class^="section__"]');
    const rest = document.querySelectorAll('.section:not([data-section="1"])');

    _.each(section1_items, item => {
      item.style.display = 'block';
      item.style.userSelect = 'initial';
    })

    _.each(rest, item => {
      item.style.display = 'grid';
      item.style.userSelect = 'initial';
    })

    setTimeout(() => {
      let timeline = anime.timeline({
        easing: [.39,.01,.2,.98]
      });

      timeline.add({
        targets: section1_items,
        opacity: 1,
        translateY: -24,
        delay: (el, i) => (i * 100),
        duration: (el, i) => (500 + (i * 100))
      }).add({
        targets: rest,
        opacity: 1,
        translateY: -60,
        delay: (el, i) => (i * 100),
        duration: (el, i) => (500 + (i * 100)),
        complete: () => resolve()
      });
    }, 400);
  });




  COMPLETED.bands_funtions = [
    COMPLETED.bands_init,
    COMPLETED.bands_anim,
    COMPLETED.display_content,
    IMAGE_OVERLAY.init
  ];









//   // CSS vars
//   let HTML_STYLES = window.getComputedStyle(document.querySelector('html'));
//   const CONTAINER = document.querySelector('.js-events');
//
//   let col_amount = parseInt(HTML_STYLES.getPropertyValue('--grid-columns')) + 2; // Side gaps
//
//   const BAND_TPL = `<span class="band"></span>`;
//
//   const bands_init = () => new Promise((resolve) => {
//     let bands_container = '';
//     for(let x = 1; x <= col_amount; x++) {
//       bands_container += BAND_TPL;
//     }
//
//     CONTAINER.innerHTML = bands_container;
//
//     resolve();
//   });
//
//
//
//   const bands_anim = () => new Promise((resolve) => {
//     let timeline = anime.timeline({
//       targets: document.querySelectorAll('.band'),
//       easing: [.39,.01,.2,.98]
//     });
//
//     timeline.add({
//       translateY: '100%',
//       delay: (el, i) => (i * 100),
//       duration: (el, i) => (1000 + (i * 50))
//     }).add({
//       width: (el, i, l) => (i + 1) == l ? 0 : 1,
//       delay: (el, i) => i * 70,
//       duration: (el, i) => 600 + (i * 50)
//     }).add({
//       backgroundColor: HTML_STYLES.getPropertyValue('--color-line').replace(/\s/g,''),
//       delay: (el, i) => (i * 100),
//       duration: (el, i) => (500 + (i * 100)),
//       offset: '-=800'
//     })
//     resolve();
//   });
//
//
//
//
//
//   // Running functions sequentially
//   const runSequentially = functions => (
//       functions.reduce((promise, next) => (
//           promise.then(next)
//       ), Promise.resolve())
//   );
//
//   const functions = [
//       bands_init,
//       bands_anim
//   ];
//
//   window.addEventListener('load', () => {
//     runSequentially(functions);
//   });
// };
