
/* 1. Global
========================================================================== */
// This function will convert pixel to rem
const toRem = (val) => {
  let html = document.getElementsByTagName('html')[0];
  let base = parseInt(window.getComputedStyle(html).fontSize);
  return parseInt((val / parseInt(base, 10)).toPrecision(4));
};


// Running functions sequentially
const runSequentially = functions => (
    functions.reduce((promise, next) => (
        promise.then(next)
    ), Promise.resolve())
);
