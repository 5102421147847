((document) => {
/*
 * NOTE: only triggered on home page
 */

  const BODY_STYLES = window.getComputedStyle(document.querySelector('body')); // CSS vars
  const BODY = document.querySelector('body');


  // Main Object and consts
  const LINES = {
    color: BODY_STYLES.getPropertyValue('--color-fixedLines'),
    distance: '1rem',
    animationDuration: 2000,
    animationEasing: [.39,.01,.2,.98]
  };

  const VERT_BAND_CLASS = 'content__band';
  const VERT_BANDS = {
    container: document.querySelector('.js-bands-container'),
    tpl: `<span class="${VERT_BAND_CLASS}"></span>`,
    amount: parseInt(BODY_STYLES.getPropertyValue('--grid-columns')) + 2 // side cols
  }




  // Init Button lines if Home page
  LINES.init = () => new Promise((resolve) => {
    // Lines Temporary - Array
    let linesColumnsTemp = [];
    let liensWhitebandsTemp = [];

    // Push Each lines to linesColumnsTemps considering their Basic declarations and
    // common declarations
    _.each(LINES.declarationsColumns, (line, index) => {
      let lineTemp = {
        top: line.top,
        right: line.right,
        bottom: line.bottom,
        left: line.left,
        width: line.direction === 'vertical' ? 1 : '100%',
        height: line.direction === 'horizontal' ? 1 : '100%',
        color: LINES.color,
        hidden: true,
        class: line.class,
        animation: {
          duration: LINES.animationDuration,
          easing: LINES.animationEasing,
          delay: line.animation.delay,
          direction: line.animation.direction
        }
      };

      linesColumnsTemp.push(lineTemp);
    });

    // Declare the LineMaker container then animate
    new LineMaker({
      position: 'fixed',
      lines: linesColumnsTemp
    }).animateLinesIn();

    resolve();
  });




  // Basic line declarations
  LINES.declarationsColumns = [{
    // Col - Left
    direction: 'vertical',
    top: 0,
    left: 'auto',
    class: ' line',
    animation: {
      delay: 100,
      direction: 'TopBottom',
    },
  }, {
    // Col - Mid
    direction: 'vertical',
    top: 0,
    left: 'auto',
    class: ' line',
    animation: {
      delay: 200,
      direction: 'TopBottom',
    },
  }, {
    // Col - Right
    direction: 'vertical',
    top: 0,
    left: 'auto',
    class: ' line',
    animation: {
      delay: 300,
      direction: 'TopBottom',
    },
  }];






  VERT_BANDS.init = () => new Promise((resolve) => {
    let bands_container = '';

    if(VERT_BANDS.amount != '') {
      for(let x = 1; x <= VERT_BANDS.amount; x++) {
        bands_container += VERT_BANDS.tpl;
      }
    } else {
      return false;
    }

    VERT_BANDS.container.innerHTML = bands_container;

    console.log(VERT_BANDS.amount);
    console.log(bands_container);

    resolve();
  });

  VERT_BANDS.anim = () => new Promise((resolve) => {
    anime({
      targets: document.querySelectorAll(`.${VERT_BAND_CLASS}`),
      translateY: '100%',
      easing: [.39,.01,.2,.98],
      delay: (el, i, l) => (i * 100),
      duration: (el, i, l) => (1000 + (i * 50))
    });
    resolve();
  });





  // Running functions sequentially
  const runSequentially = functions => (
      functions.reduce((promise, next) => (
          promise.then(next)
      ), Promise.resolve())
  );

  const functions = [
    LINES.init,
    VERT_BANDS.init,
    VERT_BANDS.anim
  ];


  // If we're on homepage
  const PAGE = BODY.dataset.page;
  if(PAGE === 'content') {
    window.addEventListener('load', () => {
      runSequentially(functions);
    });
  }

})(document);
