((document, window) => {
  const BODY = document.querySelector('body');
  const STATE_VISIBLE = 'visible';

  const LINES = {
    button: document.querySelector('.button-grid'),
    buttonIcon: document.querySelector('.button-grid__icon'),
  };

  // Init Button lines if Home page
  LINES.init = () => {
    LINES.button = document.querySelector('.button-grid');

    LINES.button.addEventListener('click', () => (
      LINES.toggleLines()
    ));

    window.addEventListener('resize', LINES.lineButtonPosition);
    window.addEventListener('load', LINES.lineButtonPosition);
  }


  // Toggle - 'data-lines' attribute on Body
  LINES.toggleLines = () => {
    let currentState = BODY.dataset.lines;

    currentState = (currentState != STATE_VISIBLE)
      ? STATE_VISIBLE
      : '';

    BODY.dataset.lines = currentState;
  };


  // Place vertically the lineButton consider the window height and rem
  LINES.lineButtonPosition = _.throttle(() => {
    let val = BODY.offsetHeight;
    val = toRem(val);
    val -= 5; // Returned value is full height, this is positioning it
    val -= 0.1; // extra pixel being the line

    LINES.button.style.top = `${val}rem`;
    LINES.button.style.bottom = 'auto';
  }, 100);


  // If Current Page is home, init()
  const PAGE = BODY.dataset.page;
  if(PAGE === 'home') {
    LINES.init();
  }

})(document, window);
