// /*
//  *  All credits for the base script: Le Lemon - https://github.com/rlemon/Konami-js
//  *
//  * NOTE: Can only be triggered on home page
//  */
// ((document) => {
//
//   let CONTENT = document.querySelector('.content');
//   if(CONTENT) { CONTENT.dataset.state = ''; }
//
//   let BODY = document.querySelector('body');
//   const TYPING = '<p class="glitch" data-content="aku">aku</p>';
//   const CLASS_HIDE = 'hidden';
//
//   const KONAMI = {
//     pos: 0,
//     // pattern: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65], // KONAMI
//     pattern: [65, 75, 85, 70, 69, 78], // AKUFEN
//     keyword: 'akufen',
//     limit: 2000,
//     timeout: null,
//   };
//
//
//   KONAMI.init = () => {
//     const BLOCK_EVENTS = document.querySelector('.js-events');
//
//     document.addEventListener('keydown', KONAMI.listen, false);
//   };
//
//
//   KONAMI.listen = (event) => {
//     // console.log(event.which);
//     // console.log(KONAMI.pos);
//     if (event.which === KONAMI.pattern[KONAMI.pos]) {
//       KONAMI.pos++;
//       clearTimeout(KONAMI.timeout);
//     } else {
//       KONAMI.reset();
//     }
//     KONAMI.timeout = setTimeout(KONAMI.reset, KONAMI.limit);
//
//     // Display typing anim
//     if (KONAMI.pos == (KONAMI.pattern.length / 2)) {
//       KONAMI.half();
//     }
//
//     // Triggered
//     if (KONAMI.pos >= KONAMI.pattern.length) {
//       KONAMI.complete();
//     }
//   };
//
//
//   KONAMI.type = (event) => {
//     let text = document.querySelector('.js-events p');
//
//     let letter = String.fromCharCode(event.keyCode);
//         letter = letter.toLowerCase();
//
//     text.innerHTML += letter;
//     text.dataset.content += letter;
//
//     // If word is completed, remove event listener
//     if (text.innerHTML === KONAMI.keyword ) {
//       document.removeEventListener('keydown', KONAMI.type, false);
//     }
//   }
//
//
//   KONAMI.reset = () => {
//     clearTimeout(KONAMI.timeout);
//
//     KONAMI.pos = 0;
//
//     let BLOCK_EVENTS = document.querySelector('.js-events');
//
//     if(BLOCK_EVENTS) {
//       BLOCK_EVENTS.style.display = 'none';
//
//       BLOCK_EVENTS.innerHTML = '';
//
//       CONTENT = document.querySelector('.content');
//       CONTENT.dataset.state = '';
//
//       document.removeEventListener('keydown', KONAMI.type, false);
//     }
//   };
//
//
//   KONAMI.half = () => {
//     let BLOCK_EVENTS = document.querySelector('.js-events');
//
//     setTimeout(() => {
//       BLOCK_EVENTS.style.display = 'inline-block';
//     }, 300);
//
//     BLOCK_EVENTS.innerHTML += TYPING;
//
//     CONTENT = document.querySelector('.content');
//     CONTENT.dataset.state = CLASS_HIDE;
//
//     document.addEventListener('keydown', KONAMI.type, false);
//   };
//
//
//   KONAMI.complete = () => {
//     // Clear timer
//     clearTimeout(KONAMI.timeout);
//
//     // Remove key events
//     document.removeEventListener('keydown', KONAMI.listen, false);
//
//     // Things to hide
//     const hidingItems = [
//       '.decolines',
//       '.home__header',
//       '.content',
//       '.home__social',
//       '.button-grid',
//       '.js-events'
//     ];
//
//     // Hide Things
//     const hideLines = anime({
//       targets: hidingItems,
//       opacity: 0,
//       duration: 500,
//       easing: [.39,.01,.2,.98],
//       complete: () => {
//         // If there's any visible state for lines
//         BODY.dataset.lines = '';
//
//         // /!\ Follow with `COMPLETED.init()`
//
//         // Init konamiCompleted.js
//         COMPLETED.init();
//       }
//     });
//   };
//
//
//
//
//   const PAGE = document.querySelector('body').dataset.page;
//
//   if(PAGE === 'home') {
//     KONAMI.init();
//   }
//
// })(document);
