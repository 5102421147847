((document) => {
/*
 * NOTE: only triggered on home page
 */

  const HTML_STYLES = window.getComputedStyle(document.querySelector('html')); // CSS vars
  const BODY = document.querySelector('body');

  // Main Object and consts
  const LINES = {
    color: HTML_STYLES.getPropertyValue('--color-fixedLines'),
    distance: '1rem',
    animationDuration: 2000,
    animationEasing: [.39,.01,.2,.98]
  };


  // Init Button lines if Home page
  LINES.init = () => {
    // Lines Temporary - Array
    let linesTemp = [];

    // Push Each lines to linesTemps considering their Basic declarations and
    // common declarations
    _.each(LINES.declarations, (line, index) => {
        let lineTemp = {
          top: line.top,
          right: line.right,
          bottom: line.bottom,
          left: line.left,
          width: line.direction === 'vertical' ? 1 : '100%',
          height: line.direction === 'horizontal' ? 1 : '100%',
          color: LINES.color,
          hidden: true,
          class: line.class,
          animation: {
            duration: LINES.animationDuration,
            easing: LINES.animationEasing,
            delay: line.animation.delay,
            direction: line.animation.direction
          }
        };

        linesTemp.push(lineTemp);
    });

    // Declare the LineMaker container
    let lineMaker = new LineMaker({
      position: 'fixed',
      lines: linesTemp
    });

    // Animation \o/
    lineMaker.animateLinesIn();
  }

  // If we're on homepage
  const PAGE = BODY.dataset.page;
  if(PAGE === 'home') {

    // Basic line declarations
    LINES.declarations = [{
      // Left Vertical
      direction: 'vertical',
      top: 0,
      left: LINES.distance,
      class: ' line line__vertical--2',
      animation: {
        delay: 200,
        direction: 'TopBottom',
      },
    }, {
      // Right Vertical
      direction: 'vertical',
      top: 0,
      right: LINES.distance,
      left: 'auto',
      class: ' line line__vertical--98',
      animation: {
        delay: 200,
        direction: 'BottomTop',
      },
    }, {
      // Top Horizontal
      direction: 'horizontal',
      top: LINES.distance,
      left: 0,
      class: ' line line__horizontal--top',
      animation: {
        delay: 600,
        direction: 'RightLeft',
      },
    }, {
      // Bottom Horizontal
      direction: 'horizontal',
      top: 'auto',
      bottom: LINES.distance,
      left: 0,
      class: ' line line__horizontal--bottom',
      animation: {
        delay: 600,
        direction: 'LeftRight',
      },
    }, {
      // Col - Left
      direction: 'vertical',
      top: 0,
      left: 'auto',
      class: ' col-xs-offset-3 line line__vertical--25',
      animation: {
        delay: 100,
        direction: 'TopBottom',
      },
    }, {
      // Col - Mid
      direction: 'vertical',
      top: 0,
      left: 'auto',
      class: ' col-xs-offset-6 line line__vertical--50',
      animation: {
        delay: 200,
        direction: 'TopBottom',
      },
    }, {
      // Col - Right
      direction: 'vertical',
      top: 0,
      left: 'auto',
      class: ' col-xs-offset-9 line line__vertical--75',
      animation: {
        delay: 300,
        direction: 'TopBottom',
      },
    }];

    LINES.init();
  }

})(document);
